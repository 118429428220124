import {
  ArrowsPointingInIcon,
  CloudArrowUpIcon,
  ScaleIcon,
  ServerIcon,
  SwatchIcon,
} from "@heroicons/react/20/solid";

const features = [
  {
    name: "Comprehensive Analysis",
    description:
      "Original Pioneers begins every client engagement with an in-depth analysis of the business, industry trends, and the competitive landscape. This foundational step allows them to identify strengths, weaknesses, opportunities, and threats, laying the groundwork for a robust marketing strategy.",
    icon: CloudArrowUpIcon,
  },
  {
    name: "Customized Strategies",
    description:
      "Recognizing that no two businesses are alike, Original Pioneers crafts tailor-made marketing strategies that address the individual needs and objectives of each client. Whether it's building brand awareness, increasing online visibility, or driving lead generation, every strategy is designed to deliver maximum impact.",
    icon: SwatchIcon,
  },
  {
    name: "Multi-channel Approach",
    description:
      " In the digital age, marketing success hinges on a diversified approach. Original Pioneers leverages a variety of marketing channels, including social media, content marketing, search engine optimization (SEO), email marketing, and more, to ensure maximum reach and engagement.",
    icon: ArrowsPointingInIcon,
  },
  {
    name: "Data-Driven Insights",
    description:
      "Original Pioneers believes in the power of data to refine and optimize marketing strategies. By constantly monitoring and analyzing campaign performance, they make data-driven adjustments that lead to higher conversion rates and better return on investment.",
    icon: ServerIcon,
  },
  {
    name: "Collaborative Partnership",
    description:
      "At Original Pioneers, clients are seen as partners. They are kept in the loop throughout the strategy development and execution phases, fostering a collaborative environment that ensures the strategy is aligned with the client's vision.",
    icon: ScaleIcon,
  },
];

const PageSolutionsMarketing: React.FC = () => {
  return (
    <>
      <div className="overflow-hidden bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl md:px-6 lg:px-8">
          <div className="grid grid-cols-1 gap-y-16 gap-x-8 sm:gap-y-20 lg:grid-cols-2 lg:items-start">
            <div className="px-6 lg:px-0 lg:pt-4 lg:pr-4">
              <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-lg">
                <h2 className="text-lg font-semibold leading-8 tracking-tight text-indigo-600">
                  Market More Effectivley
                </h2>
                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  A better plan
                </p>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                  In the dynamic and competitive landscape of modern business,
                  small and medium-sized enterprises (SMEs) often face the
                  challenge of effectively reaching their target audience and
                  standing out in a crowded marketplace. This is where Original
                  Pioneers comes into play. As a leading marketing strategy
                  company dedicated to serving SMEs, Original Pioneers
                  specializes in creating customized marketing solutions that
                  propel businesses to new heights of success.
                </p>
                <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                  {features.map((feature) => (
                    <div key={feature.name} className="relative pl-9">
                      <dt className="inline font-semibold text-gray-900">
                        <feature.icon
                          className="absolute top-1 left-1 h-5 w-5 text-indigo-600"
                          aria-hidden="true"
                        />
                        {feature.name}
                      </dt>{" "}
                      <dd className="inline">{feature.description}</dd>
                    </div>
                  ))}
                </dl>
              </div>
            </div>
            <div className="sm:px-6 lg:px-0">
              <div className="relative isolate overflow-hidden bg-indigo-500 px-6 pt-8 sm:mx-auto sm:max-w-2xl sm:rounded-3xl sm:pt-16 sm:pl-16 sm:pr-0 lg:mx-0 lg:max-w-none">
                <div
                  className="absolute -inset-y-px -left-3 -z-10 w-full origin-bottom-left skew-x-[-30deg] bg-indigo-100 opacity-20 ring-1 ring-inset ring-white"
                  aria-hidden="true"
                />
                <div className="mx-auto max-w-2xl sm:mx-0 sm:max-w-none">
                  <img
                    src="https://images.unsplash.com/photo-1533750349088-cd871a92f312?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80"
                    alt="Product screenshot"
                    width={2432}
                    height={1442}
                    className="-mb-12 w-[57rem] max-w-none rounded-tl-xl bg-gray-800 ring-1 ring-white/10"
                  />
                </div>
                <div
                  className="pointer-events-none absolute inset-0 ring-1 ring-inset ring-black/10 sm:rounded-3xl"
                  aria-hidden="true"
                />
              </div>
              <br />
              <div className="relative isolate overflow-hidden bg-indigo-500 px-6 pt-8 sm:mx-auto sm:max-w-2xl sm:rounded-3xl sm:pt-16 sm:pl-16 sm:pr-0 lg:mx-0 lg:max-w-none">
                <div
                  className="absolute -inset-y-px -left-3 -z-10 w-full origin-bottom-left skew-x-[-30deg] bg-indigo-100 opacity-20 ring-1 ring-inset ring-white"
                  aria-hidden="true"
                />
                <div className="mx-auto max-w-2xl sm:mx-0 sm:max-w-none">
                  <img
                    src="https://images.unsplash.com/photo-1432888498266-38ffec3eaf0a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1174&q=80"
                    alt="Product screenshot"
                    width={2432}
                    height={1442}
                    className="-mb-12 w-[57rem] max-w-none rounded-tl-xl bg-gray-800 ring-1 ring-white/10"
                  />
                </div>
                <div
                  className="pointer-events-none absolute inset-0 ring-1 ring-inset ring-black/10 sm:rounded-3xl"
                  aria-hidden="true"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageSolutionsMarketing;
