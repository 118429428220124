import {
  ChatBubbleLeftRightIcon,
  DocumentTextIcon,
  HeartIcon,
  InboxIcon,
  PencilSquareIcon,
  ChatBubbleBottomCenterTextIcon,
  TrashIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";

const features = [
  {
    name: "Websites",
    description: "Update your existing website or launch a new one.",
    icon: InboxIcon,
  },
  {
    name: "Mobile Apps",
    description: "Andorid and iOS mobile application development.",
    icon: UsersIcon,
  },
  {
    name: "Web Apps",
    description: "Get a web based application built with an API.",
    icon: TrashIcon,
  },
  {
    name: "Digital Marketing",
    description:
      "List your company on the top engines and gain and track traffic.",
    icon: PencilSquareIcon,
  },
  {
    name: "Social Media",
    description: "Facebook, Instagram, Twitter, LinkedIn, TikTok.",
    icon: DocumentTextIcon,
  },
  {
    name: "Business Setup",
    description: "Get help establishing your business.",
    icon: ChatBubbleBottomCenterTextIcon,
  },
  {
    name: "Technology Consulting",
    description: "Google, Microsoft 365, email, file sharing and more.",
    icon: ChatBubbleLeftRightIcon,
  },
  {
    name: "Digital Graphics",
    description: "Revamp your logo or create a new one.",
    icon: HeartIcon,
  },
];

export { features };
