import {
    ComputerDesktopIcon,
    ChatBubbleLeftRightIcon,
    InboxIcon,
    QuestionMarkCircleIcon,
  } from '@heroicons/react/24/outline'

const solutions = [
  {
    name: "Marketing Strategy",
    description:
      "Get a better understanding of where your traffic is coming from.",
    href: "/solutions/marketing",
    icon: InboxIcon,
  },
  {
    name: "Websites",
    description: "Speak directly to your customers in a more meaningful way.",
    href: "/solutions/websites",
    icon: ComputerDesktopIcon,
  },
  {
    name: "Web & Mobile Apps",
    description: "Your customers' data will be safe and secure.",
    href: "/solutions/apps",
    icon: ChatBubbleLeftRightIcon,
  },
  {
    name: "Social Media",
    description: "Connect with third-party tools that you're already using.",
    href: "/solutions/social-media",
    icon: QuestionMarkCircleIcon,
  },
];

export { solutions };
