const metrics = [
  {
    id: 1,
    stat: "8K+",
    emphasis: "Companies",
    rest: "Have used our softwares.",
  },
  {
    id: 2,
    stat: "25K+",
    emphasis: "Daily active users",
    rest: "Large scale traffic for business.",
  },
  {
    id: 3,
    stat: "98%",
    emphasis: "Customer satisfaction",
    rest: ", we strive to make our customers happy.",
  },
  {
    id: 4,
    stat: "12M+",
    emphasis: "Revenue generated",
    rest: " to date for our customers.",
  },
];

export { metrics };
