// Local - Data
import { services } from "./data";

const PageServices = () => {
  return (
    <>
      <div className="bg-white py-12 px-6 sm:py-16 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <p className="text-lg font-semibold leading-8 tracking-tight text-indigo-600">
            Get the help you need
          </p>
          <h2 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
            Solutions & Services
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui
            lorem cupidatat commodo. Elit sunt amet fugiat veniam occaecat
            fugiat aliqua.
          </p>
        </div>
      </div>
      <div className="mx-auto max-w-7xl md:px-6 lg:px-8 my-4">
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-4">
          {services.map((service) => (
            <div
              key={service.name}
              className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400"
            >
              <div className="flex-shrink-0">
                <img
                  className="h-10 w-10 rounded-full"
                  src={service.imageUrl}
                  alt=""
                />
              </div>
              <div className="min-w-0 flex-1">
                <a href={service.url} className="focus:outline-none">
                  <span className="absolute inset-0" aria-hidden="true" />
                  <p className="text-sm font-medium text-gray-900">
                    {service.name}
                  </p>
                  <p className="truncate text-sm text-gray-500">
                    {service.role}
                  </p>
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default PageServices;
