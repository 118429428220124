import { CheckIcon } from "@heroicons/react/20/solid";

const packages = [
  {
    name: "Pioneer Membership - Small Business",
    description:
      "Perfect for small businesses looking to offload the management of their digital marketing, website and social media management.",
    price: 349,
    pricePer: "/ month",
    priceDescription: "Simple Single Payment",
    features: [
      "Website Hosting",
      "SEO Optimization",
      "Digital Advertising",
      "Social Media Management",
      "Ongoing Support",
    ],
  },
  {
    name: "Website Development",
    description:
      "Lorem ipsum dolor sit amet consect etur adipisicing elit. Itaque amet indis perferendis blanditiis repellendus etur quidem assumenda.",
    price: "2,500",
    pricePer: "",
    priceDescription: "Starting At",
    features: [
      "Website Design",
      "SEO Optimization",
      "Digital Advertising",
      "Social Media Integration",
      "Ongoing Support",
    ],
  },
  {
    name: "App Development",
    description:
      "Lorem ipsum dolor sit amet consect etur adipisicing elit. Itaque amet indis perferendis blanditiis repellendus etur quidem assumenda.",
    price: "5,000",
    pricePer: "",
    priceDescription: "Starting At",
    features: [
      "Front-End Code",
      "Back-End Code",
      "API's",
      "Database Design",
      "Ongoing Support",
    ],
  },
];

const PagePricing: React.FC = () => {
  return (
    <>
      <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl sm:text-center">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Simple no-tricks pricing
            </h2>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              At Original Pioneers we offer custom project based pricing for all
              of our services. We also offer a monthly membership for small
              businesses looking to offload the management of their digital
              marketing, website and social media management.
            </p>
          </div>
          {packages.map((pkg) => {
            return (
              <div
                className="mx-auto mt-8 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-10 lg:mx-0 lg:flex lg:max-w-none"
                key={pkg.name}
              >
                <div className="p-8 sm:p-10 lg:flex-auto">
                  <h3 className="text-2xl font-bold tracking-tight text-gray-900">
                    {pkg.name}
                  </h3>
                  <p className="mt-6 text-base leading-7 text-gray-600">
                    {pkg.description}
                  </p>
                  <div className="mt-10 flex items-center gap-x-4">
                    <h4 className="flex-none text-sm font-semibold leading-6 text-indigo-600">
                      What's included
                    </h4>
                    <div className="h-px flex-auto bg-gray-100" />
                  </div>
                  <ul className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6">
                    {pkg.features.map((feature) => (
                      <li key={feature} className="flex gap-x-3">
                        <CheckIcon
                          className="h-6 w-5 flex-none text-indigo-600"
                          aria-hidden="true"
                        />
                        {feature}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
                  <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
                    <div className="mx-auto max-w-xs px-8">
                      <p className="text-base font-semibold text-gray-600">
                        {pkg.priceDescription}
                      </p>
                      <p className="mt-6 flex items-baseline justify-center gap-x-2">
                        <span className="text-5xl font-bold tracking-tight text-gray-900">
                          ${pkg.price}
                        </span>
                        <span className="text-sm font-semibold leading-6 tracking-wide text-gray-600">
                          USD {pkg.pricePer}
                        </span>
                      </p>
                      <a
                        href="/contact"
                        className="mt-10 block w-full rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        Schedule A Call
                      </a>
                      <p className="mt-6 text-xs leading-5 text-gray-600">
                        Invoices and receipts available for easy company
                        reimbursement
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default PagePricing;
