import {
  BanknotesIcon,
  ComputerDesktopIcon,
  CpuChipIcon,
  LifebuoyIcon,
  LightBulbIcon,
  ScaleIcon,
  SwatchIcon,
  UserIcon,
} from "@heroicons/react/20/solid";

const features = [
  {
    name: "Visionary Expertise",
    description:
      "At Original Pioneers, we don't just build websites; we craft digital experiences that resonate with your brand's essence. Our team of visionary designers, developers, and strategists work collaboratively to create websites that are not only visually appealing but also highly functional, ensuring seamless user experiences.",
    icon: LightBulbIcon,
  },
  {
    name: "Tailored Solutions",
    description:
      "Recognizing that every business is unique, Original Pioneers takes a personalized approach to website development. We take the time to understand your business goals, target audience, and industry nuances, allowing us to design and develop websites that are tailored to your specific needs.",
    icon: SwatchIcon,
  },
  {
    name: "Cutting-Edge Technology",
    description:
      "In a fast-paced digital world, staying ahead requires embracing the latest technological advancements. Original Pioneers is at the forefront of web development trends, utilizing cutting-edge tools and frameworks to build websites that are modern, responsive, and adaptable to various devices and screen sizes.",
    icon: CpuChipIcon,
  },
  {
    name: "User-Centric Design",
    description:
      "User experience is a cornerstone of our philosophy. We design websites that captivate visitors from the first click, guiding them intuitively through the content and converting them into loyal customers. Our focus on user-centric design ensures that your website becomes an engaging hub for your brand.",
    icon: UserIcon,
  },
  {
    name: "SEO Integration",
    description:
      "A visually appealing website is only effective if it can be found by potential customers. Original Pioneers seamlessly integrates search engine optimization (SEO) strategies into the website development process, boosting your website's visibility on search engines and driving organic traffic.",
    icon: ComputerDesktopIcon,
  },
  {
    name: "Ongoing Support",
    description:
      "Our relationship doesn't end once the website is live. Original Pioneers offers ongoing support and maintenance services to ensure your website remains up-to-date, secure, and optimized for peak performance. As your business evolves, so should your digital presence.",
    icon: LifebuoyIcon,
  },
  {
    name: "Affordable Excellence",
    description:
      "We understand the budget constraints that small and medium businesses often face. That's why we offer affordable website development packages without compromising on quality. Original Pioneers believes that every business, regardless of its size, deserves a remarkable online presence.",
    icon: BanknotesIcon,
  },
  {
    name: "Client-Centric Approach",
    description:
      "The success of our clients is our driving force. We foster a culture of open communication and collaboration, keeping you involved at every stage of the website development process. Your feedback is invaluable, and we take it seriously to ensure the end result aligns perfectly with your vision.",
    icon: ScaleIcon,
  },
];

const PageSolutionsWebsites: React.FC = () => {
  return (
    <>
      <div className="overflow-hidden bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl md:px-6 lg:px-8">
          <div className="grid grid-cols-1 gap-y-16 gap-x-8 sm:gap-y-20 lg:grid-cols-2 lg:items-start">
            <div className="px-6 lg:px-0 lg:pt-4 lg:pr-4">
              <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-lg">
                <h2 className="text-lg font-semibold leading-8 tracking-tight text-indigo-600">
                  Beautiful &amp; Effecient Web Presence
                </h2>
                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  A better website
                </p>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                  In an era where the digital landscape is constantly evolving,
                  having a strong online presence is not just an option but a
                  necessity for businesses of all sizes. Enter Original
                  Pioneers, a dynamic and innovative company dedicated to
                  helping small and medium businesses thrive in the digital age.
                  With a passion for technology and a commitment to excellence,
                  Original Pioneers specializes in offering top-notch website
                  development services that empower businesses to stand out in
                  the competitive online marketplace.
                </p>
                <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                  {features.map((feature) => (
                    <div key={feature.name} className="relative pl-9">
                      <dt className="inline font-semibold text-gray-900">
                        <feature.icon
                          className="absolute top-1 left-1 h-5 w-5 text-indigo-600"
                          aria-hidden="true"
                        />
                        {feature.name}
                      </dt>{" "}
                      <dd className="inline">{feature.description}</dd>
                    </div>
                  ))}
                </dl>
              </div>
            </div>
            <div className="sm:px-6 lg:px-0">
              <div className="relative isolate overflow-hidden bg-indigo-500 px-6 pt-8 sm:mx-auto sm:max-w-2xl sm:rounded-3xl sm:pt-16 sm:pl-16 sm:pr-0 lg:mx-0 lg:max-w-none">
                <div
                  className="absolute -inset-y-px -left-3 -z-10 w-full origin-bottom-left skew-x-[-30deg] bg-indigo-100 opacity-20 ring-1 ring-inset ring-white"
                  aria-hidden="true"
                />
                <div className="mx-auto max-w-2xl sm:mx-0 sm:max-w-none">
                  <img
                    src="https://images.unsplash.com/photo-1496171367470-9ed9a91ea931?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80"
                    alt="Product screenshot"
                    width={2432}
                    height={1442}
                    className="-mb-12 w-[57rem] max-w-none rounded-tl-xl bg-gray-800 ring-1 ring-white/10"
                  />
                </div>
                <div
                  className="pointer-events-none absolute inset-0 ring-1 ring-inset ring-black/10 sm:rounded-3xl"
                  aria-hidden="true"
                />
              </div>
              <br />
              <div className="relative isolate overflow-hidden bg-indigo-500 px-6 pt-8 sm:mx-auto sm:max-w-2xl sm:rounded-3xl sm:pt-16 sm:pl-16 sm:pr-0 lg:mx-0 lg:max-w-none">
                <div
                  className="absolute -inset-y-px -left-3 -z-10 w-full origin-bottom-left skew-x-[-30deg] bg-indigo-100 opacity-20 ring-1 ring-inset ring-white"
                  aria-hidden="true"
                />
                <div className="mx-auto max-w-2xl sm:mx-0 sm:max-w-none">
                  <img
                    src="https://images.unsplash.com/photo-1522542550221-31fd19575a2d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80"
                    alt="Product screenshot"
                    width={2432}
                    height={1442}
                    className="-mb-12 w-[57rem] max-w-none rounded-tl-xl bg-gray-800 ring-1 ring-white/10"
                  />
                </div>
                <div
                  className="pointer-events-none absolute inset-0 ring-1 ring-inset ring-black/10 sm:rounded-3xl"
                  aria-hidden="true"
                />
              </div>
              <br />
              <div className="relative isolate overflow-hidden bg-indigo-500 px-6 pt-8 sm:mx-auto sm:max-w-2xl sm:rounded-3xl sm:pt-16 sm:pl-16 sm:pr-0 lg:mx-0 lg:max-w-none">
                <div
                  className="absolute -inset-y-px -left-3 -z-10 w-full origin-bottom-left skew-x-[-30deg] bg-indigo-100 opacity-20 ring-1 ring-inset ring-white"
                  aria-hidden="true"
                />
                <div className="mx-auto max-w-2xl sm:mx-0 sm:max-w-none">
                  <img
                    src="https://images.unsplash.com/photo-1426024120108-99cc76989c71?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1174&q=80"
                    alt="Product screenshot"
                    width={2432}
                    height={1442}
                    className="-mb-12 w-[57rem] max-w-none rounded-tl-xl bg-gray-800 ring-1 ring-white/10"
                  />
                </div>
                <div
                  className="pointer-events-none absolute inset-0 ring-1 ring-inset ring-black/10 sm:rounded-3xl"
                  aria-hidden="true"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageSolutionsWebsites;
