import {
  imgScreenshotCannaStack,
  imgScreenshotFinify,
  imgScreenshotMassPile,
  imgScreenshotStorytime,
  imgScreenshotWeStride,
} from "assets";

export const projects = [
  {
    title: "Finify",
    href: "#",
    category: { name: "Financial Technology", href: "https://www.finify.io" },
    description:
      "Finify is a personal finance management platform that helps you to manage your money, track your spending, and save more money.  Built as an API, web application, Android and iOS mobile applications.",
    date: "Mar 16, 2020",
    datetime: "2020-03-16",
    imageUrl: imgScreenshotFinify,
    readingTime: "6 min",
    author: {
      name: "Roel Aufderehar",
      href: "#",
      imageUrl:
        "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
  },
  {
    title: "WeStride",
    href: "#",
    category: { name: "Social Networking", href: "https://www.westride.io" },
    description:
      "WeStride empowers historically underrepresented groups by improving education, connecting you to your community, highlighting events and programs as well as providing insight into open jobs and entrepreneurship opportunities.",
    date: "Mar 10, 2020",
    datetime: "2020-03-10",
    imageUrl: imgScreenshotWeStride,
    readingTime: "4 min",
    author: {
      name: "Brenna Goyette",
      href: "#",
      imageUrl:
        "https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
  },
  {
    title: "MassPile",
    href: "#",
    category: { name: "Crypto Currency", href: "https://www.masspile.com" },
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint harum rerum voluptatem quo recusandae magni placeat saepe molestiae, sed excepturi cumque corporis perferendis hic.",
    date: "Feb 12, 2020",
    datetime: "2020-02-12",
    imageUrl: imgScreenshotMassPile,
    readingTime: "11 min",
    author: {
      name: "Daniela Metz",
      href: "#",
      imageUrl:
        "https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
  },
  {
    title: "Storytime.AI",
    href: "#",
    category: {
      name: "Artifical Intelligence",
      href: "https://www.getstorytime.ai",
    },
    description:
      "Storytime.AI generates stories on the fly, in real-time using artificial intelligence. Kids can pick from a set of genres, select a hero, villain and location or enter their own! Our AI will generate a story that can be listened to or read at their own pace!",
    date: "Feb 12, 2020",
    datetime: "2020-02-12",
    imageUrl: imgScreenshotStorytime,
    readingTime: "11 min",
    author: {
      name: "Daniela Metz",
      href: "#",
      imageUrl:
        "https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
  },
  {
    title: "CannaStack",
    href: "#",
    category: { name: "Social Networking", href: "https://www.cannastack.io" },
    description:
      "CannaStack serves as a social network for cannabis businesses.  It is a place where you can connect with other cannabis businesses, share your experiences, and learn about new products and services.",
    date: "Feb 12, 2020",
    datetime: "2020-02-12",
    imageUrl: imgScreenshotCannaStack,
    readingTime: "11 min",
    author: {
      name: "Daniela Metz",
      href: "#",
      imageUrl:
        "https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
  },
];
