import imgGarrett from "assets/img/garrett.jpg";
import imgLauren from "assets/img/lauren.jpg";

const people = [
  {
    name: "Garrett Hampton",
    role: "Technology",
    imageUrl: imgGarrett,
    bio: "A polyglot technology executive veteran with 15+ years of experience, multiple acquisitions, patents and a passion for building great teams and products.  Garrett loves the outdoors, playing music and volunteering in the community.",
  },
  {
    name: "Lauren Hampton",
    role: "Marketing & Design",
    imageUrl: imgLauren,
    bio: "Creative, passionate and driven marketing and design professional with 10+ years of experience in the industry. Lauren has a passion for helping businesses grow and succeed through creative marketing and design strategies.",
  },
];

const PageCompany: React.FC = () => {
  return (
    <>
      <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl sm:text-center">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Meet our leadership
            </h2>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              We are a dynamic group of individuals who are passionate about
              what we do and dedicated to delivering the best results for our
              clients.
            </p>
          </div>
          <ul className="mx-auto mt-20 grid max-w-2xl grid-cols-1 gap-x-6 gap-y-20 sm:grid-cols-2 lg:max-w-4xl lg:gap-x-8 xl:max-w-none">
            {people.map((person) => (
              <li key={person.name} className="flex flex-col gap-6 xl:flex-row">
                <img
                  className="aspect-[4/5] w-52 flex-none rounded-2xl object-cover"
                  src={person.imageUrl}
                  alt=""
                />
                <div className="flex-auto">
                  <h3 className="text-lg font-semibold leading-8 tracking-tight text-gray-900">
                    {person.name}
                  </h3>
                  <p className="text-base leading-7 text-gray-600">
                    {person.role}
                  </p>
                  <p className="mt-6 text-base leading-7 text-gray-600">
                    {person.bio}
                  </p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default PageCompany;
