import {
  ArrowsRightLeftIcon,
  BanknotesIcon,
  BeakerIcon,
  BoltIcon,
  CloudArrowUpIcon,
  LightBulbIcon,
  ScaleIcon,
  SwatchIcon,
  UsersIcon,
} from "@heroicons/react/20/solid";

const features = [
  {
    name: "Visionary Craftsmanship",
    description:
      "At Original Pioneers, we don't just develop applications; we craft digital experiences that resonate with your brand's identity. Our team of visionary designers, developers, and strategists collaborate to create applications that blend cutting-edge functionality with intuitive design, ensuring a seamless user experience.",
    icon: CloudArrowUpIcon,
  },
  {
    name: "Customized Solutions",
    description:
      "We recognize that every business has unique challenges and aspirations. Original Pioneers takes a personalized approach, delving deep into your business objectives and user requirements to develop applications that are tailor-made to suit your specific needs.",
    icon: SwatchIcon,
  },
  {
    name: "Cross-Platform Expertise",
    description:
      "The modern digital landscape demands applications that transcend platforms. Original Pioneers excels in creating cross-platform applications that seamlessly adapt to various devices, operating systems, and screen sizes, ensuring a consistent user experience across the board.",
    icon: ArrowsRightLeftIcon,
  },
  {
    name: "Innovative Technology",
    description:
      "Staying ahead in the digital realm requires embracing the latest technological advancements. Original Pioneers remains at the forefront of web and mobile app development trends, utilizing cutting-edge tools and frameworks to deliver applications that are both innovative and reliable.",
    icon: LightBulbIcon,
  },
  {
    name: "User-Centric Design",
    description:
      "The heart of our approach lies in user-centric design. We create applications that captivate users from their first interaction, guiding them intuitively through the features and functions while fostering engagement and conversion.",
    icon: UsersIcon,
  },
  {
    name: "Seamless Integration",
    description:
      "In an interconnected world, integration is key. Original Pioneers ensures that your applications seamlessly integrate with your existing digital ecosystem, enabling streamlined processes and enhanced user experiences.",
    icon: BoltIcon,
  },
  {
    name: "Continuous Innovation",
    description:
      "Our commitment to your success goes beyond the development phase. Original Pioneers offers ongoing support and updates to ensure that your applications remain relevant and effective as your business evolves and technology advances.",
    icon: BeakerIcon,
  },
  {
    name: "Affordable Excellence",
    description:
      "We understand the resource constraints that small and medium businesses often face. Original Pioneers offers competitive pricing without compromising on quality, making the power of web and mobile applications accessible to businesses of all sizes.",
    icon: BanknotesIcon,
  },
  {
    name: "Collaborative Partnership",
    description:
      "Your vision drives us. We foster a collaborative partnership, valuing your insights at every stage of the development process. Your feedback guides us to create applications that not only meet but exceed your expectations.",
    icon: ScaleIcon,
  },
];

const PageSolutionsApps: React.FC = () => {
  return (
    <>
      <div className="overflow-hidden bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl md:px-6 lg:px-8">
          <div className="grid grid-cols-1 gap-y-16 gap-x-8 sm:gap-y-20 lg:grid-cols-2 lg:items-start">
            <div className="px-6 lg:px-0 lg:pt-4 lg:pr-4">
              <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-lg">
                <h2 className="text-lg font-semibold leading-8 tracking-tight text-indigo-600">
                  Web &amp; Mobile Apps
                </h2>
                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  Modern Application Development
                </p>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                  In the ever-evolving landscape of technology, harnessing the
                  potential of web and mobile applications is no longer a luxury
                  but a necessity for businesses striving to thrive in the
                  digital age. Welcome to Original Pioneers, a dynamic and
                  innovative company that stands at the forefront of web and
                  mobile application development, offering tailored solutions to
                  empower small and medium businesses on their digital journey.
                </p>
                <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                  {features.map((feature) => (
                    <div key={feature.name} className="relative pl-9">
                      <dt className="inline font-semibold text-gray-900">
                        <feature.icon
                          className="absolute top-1 left-1 h-5 w-5 text-indigo-600"
                          aria-hidden="true"
                        />
                        {feature.name}
                      </dt>{" "}
                      <dd className="inline">{feature.description}</dd>
                    </div>
                  ))}
                </dl>
              </div>
            </div>
            <div className="sm:px-6 lg:px-0">
              <div className="relative isolate overflow-hidden bg-indigo-500 px-6 pt-8 sm:mx-auto sm:max-w-2xl sm:rounded-3xl sm:pt-16 sm:pl-16 sm:pr-0 lg:mx-0 lg:max-w-none">
                <div
                  className="absolute -inset-y-px -left-3 -z-10 w-full origin-bottom-left skew-x-[-30deg] bg-indigo-100 opacity-20 ring-1 ring-inset ring-white"
                  aria-hidden="true"
                />
                <div className="mx-auto max-w-2xl sm:mx-0 sm:max-w-none">
                  <img
                    src="https://tailwindui.com/img/component-images/project-app-screenshot.png"
                    alt="Product screenshot"
                    width={2432}
                    height={1442}
                    className="-mb-12 w-[57rem] max-w-none rounded-tl-xl bg-gray-800 ring-1 ring-white/10"
                  />
                </div>
                <div
                  className="pointer-events-none absolute inset-0 ring-1 ring-inset ring-black/10 sm:rounded-3xl"
                  aria-hidden="true"
                />
              </div>
              <br />
              <div className="relative isolate overflow-hidden bg-indigo-500 px-6 pt-8 sm:mx-auto sm:max-w-2xl sm:rounded-3xl sm:pt-16 sm:pl-16 sm:pr-0 lg:mx-0 lg:max-w-none">
                <div
                  className="absolute -inset-y-px -left-3 -z-10 w-full origin-bottom-left skew-x-[-30deg] bg-indigo-100 opacity-20 ring-1 ring-inset ring-white"
                  aria-hidden="true"
                />
                <div className="mx-auto max-w-2xl sm:mx-0 sm:max-w-none">
                  <img
                    src="https://images.unsplash.com/photo-1551650975-87deedd944c3?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1074&q=80"
                    alt="Product screenshot"
                    width={2432}
                    height={1442}
                    className="-mb-12 w-[57rem] max-w-none rounded-tl-xl bg-gray-800 ring-1 ring-white/10"
                  />
                </div>
                <div
                  className="pointer-events-none absolute inset-0 ring-1 ring-inset ring-black/10 sm:rounded-3xl"
                  aria-hidden="true"
                />
              </div>
              <br />
              <div className="relative isolate overflow-hidden bg-indigo-500 px-6 pt-8 sm:mx-auto sm:max-w-2xl sm:rounded-3xl sm:pt-16 sm:pl-16 sm:pr-0 lg:mx-0 lg:max-w-none">
                <div
                  className="absolute -inset-y-px -left-3 -z-10 w-full origin-bottom-left skew-x-[-30deg] bg-indigo-100 opacity-20 ring-1 ring-inset ring-white"
                  aria-hidden="true"
                />
                <div className="mx-auto max-w-2xl sm:mx-0 sm:max-w-none">
                  <img
                    src="https://images.unsplash.com/photo-1514996550219-62672472d03b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80"
                    alt="Product screenshot"
                    width={2432}
                    height={1442}
                    className="-mb-12 w-[57rem] max-w-none rounded-tl-xl bg-gray-800 ring-1 ring-white/10"
                  />
                </div>
                <div
                  className="pointer-events-none absolute inset-0 ring-1 ring-inset ring-black/10 sm:rounded-3xl"
                  aria-hidden="true"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageSolutionsApps;
