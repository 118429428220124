export const imgLogo = require('./img/logo.png');

export const imgLogoCannaStack = require('./img/logos/cannastack.png');
export const imgLogoFinify = require('./img/logos/finify.png');
export const imgLogoMassPile = require('./img/logos/masspile.png');
export const imgLogoStorytime = require('./img/logos/storytime.png');
export const imgLogoWeStride = require('./img/logos/westride.png');

export const imgScreenshotCannaStack = require('./img/screenshots/cannastack.png');
export const imgScreenshotFinify = require('./img/screenshots/finify.png');
export const imgScreenshotMassPile = require('./img/screenshots/masspile.png');
export const imgScreenshotStorytime = require('./img/screenshots/storytime.png');
export const imgScreenshotWeStride = require('./img/screenshots/westride.png');