import { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import Layout from "layout";
// Pages
import PageHome from "pages/home";
import PageIncubator from "pages/incubator";
import PageCompany from "pages/company";
import PageContact from "pages/contact";
import PageIndustries from "pages/industries";
import PagePortfolio from "pages/portfolio";
import PagePricing from "pages/pricing";
import PageServices from "pages/services";
import PageSolutionsApps from "pages/solutions/apps";
import PageSolutionsLayout from "pages/solutions/layout";
import PageSolutionsMarketing from "pages/solutions/marketing";
import PageSolutionsSocialMedia from "pages/solutions/social-media";
import PageSolutionsWebsites from "pages/solutions/websites";
import PageAuthLayout from "pages/auth/layout";
import PageAuthLogin from "pages/auth/login";

const AppRoutes = () => {
  return (
    <Suspense>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route element={<PageHome />} index />
          <Route path="company" element={<PageCompany />} />
          <Route path="contact" element={<PageContact />} />
          <Route path="incubator" element={<PageIncubator />} />
          <Route path="industries" element={<PageIndustries />} />
          <Route path="portfolio" element={<PagePortfolio />} />
          <Route path="pricing" element={<PagePricing />} />
          <Route path="services" element={<PageServices />} />
          <Route path="solutions" element={<PageSolutionsLayout />}>
            <Route path="apps" element={<PageSolutionsApps />} />
            <Route path="marketing" element={<PageSolutionsMarketing />} />
            <Route path="social-media" element={<PageSolutionsSocialMedia />} />
            <Route path="websites" element={<PageSolutionsWebsites />} />
          </Route>
        </Route>
        <Route path="auth" element={<PageAuthLayout />}>
          <Route path="login" element={<PageAuthLogin />} />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
