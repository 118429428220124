import {
  CalendarDaysIcon,
  ComputerDesktopIcon,
  PencilSquareIcon,
  PresentationChartLineIcon,
  TableCellsIcon,
  UsersIcon,
} from "@heroicons/react/20/solid";

const features = [
  {
    name: "Strategic Planning",
    description:
      "We understand that every business has distinct goals. That's why we start by collaboratively designing a customized social media strategy that aligns with your objectives and resonates with your brand's identity.",
    icon: CalendarDaysIcon,
  },
  {
    name: "Content Creation",
    description:
      "Our creative wizards develop captivating content that captivates and informs your audience. From attention-grabbing visuals to compelling copy, we ensure that your message is conveyed effectively across platforms.",
    icon: PencilSquareIcon,
  },
  {
    name: "Community Engagement",
    description:
      "Building an online community around your brand is crucial. We handle comments, messages, and interactions, fostering meaningful connections with your audience that go beyond mere likes and shares.",
    icon: UsersIcon,
  },
  {
    name: "Data-Driven Insights",
    description:
      "Our analytical prowess sets us apart. We continuously monitor the performance of your campaigns, offering valuable insights that drive optimization and decision-making.",
    icon: PresentationChartLineIcon,
  },
  {
    name: "Ad Campaigns",
    description:
      "Boost your reach with targeted ad campaigns. We manage the intricacies of ad placements, ensuring your investment generates the desired returns.",
    icon: TableCellsIcon,
  },
  {
    name: "Platform Management",
    description:
      "From Facebook to Instagram, Twitter to LinkedIn, we are well-versed in managing diverse platforms, ensuring a cohesive online presence across the digital landscape.",
    icon: ComputerDesktopIcon,
  },
];

const PageSolutionsSocialMedia: React.FC = () => {
  return (
    <>
      <div className="overflow-hidden bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl md:px-6 lg:px-8">
          <div className="grid grid-cols-1 gap-y-16 gap-x-8 sm:gap-y-20 lg:grid-cols-2 lg:items-start">
            <div className="px-6 lg:px-0 lg:pt-4 lg:pr-4">
              <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-lg">
                <h2 className="text-lg font-semibold leading-8 tracking-tight text-indigo-600">
                  Be Seen By Your Target Audience
                </h2>
                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  Social Media
                </p>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                  In the dynamic landscape of the digital age, a robust social
                  media presence can make all the difference for small and
                  medium businesses aiming to thrive and expand their reach.
                  That's where "Original Pioneers" come into play, your
                  dedicated partners in navigating the intricacies of social
                  media management. With a passion for innovation and a keen
                  understanding of the ever-evolving social media realm, we are
                  committed to propelling your business towards new horizons.
                </p>
                <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                  {features.map((feature) => (
                    <div key={feature.name} className="relative pl-9">
                      <dt className="inline font-semibold text-gray-900">
                        <feature.icon
                          className="absolute top-1 left-1 h-5 w-5 text-indigo-600"
                          aria-hidden="true"
                        />
                        {feature.name}
                      </dt>{" "}
                      <dd className="inline">{feature.description}</dd>
                    </div>
                  ))}
                </dl>
              </div>
            </div>
            <div className="sm:px-6 lg:px-0">
              <div className="relative isolate overflow-hidden bg-indigo-500 px-6 pt-8 sm:mx-auto sm:max-w-2xl sm:rounded-3xl sm:pt-16 sm:pl-16 sm:pr-0 lg:mx-0 lg:max-w-none">
                <div
                  className="absolute -inset-y-px -left-3 -z-10 w-full origin-bottom-left skew-x-[-30deg] bg-indigo-100 opacity-20 ring-1 ring-inset ring-white"
                  aria-hidden="true"
                />
                <div className="mx-auto max-w-2xl sm:mx-0 sm:max-w-none">
                  <img
                    src="https://images.unsplash.com/photo-1579869847557-1f67382cc158?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1334&q=80"
                    alt="Product screenshot"
                    width={2432}
                    height={1442}
                    className="-mb-12 w-[57rem] max-w-none rounded-tl-xl bg-gray-800 ring-1 ring-white/10"
                  />
                </div>
                <div
                  className="pointer-events-none absolute inset-0 ring-1 ring-inset ring-black/10 sm:rounded-3xl"
                  aria-hidden="true"
                />
              </div>
              <br />
              <div className="relative isolate overflow-hidden bg-indigo-500 px-6 pt-8 sm:mx-auto sm:max-w-2xl sm:rounded-3xl sm:pt-16 sm:pl-16 sm:pr-0 lg:mx-0 lg:max-w-none">
                <div
                  className="absolute -inset-y-px -left-3 -z-10 w-full origin-bottom-left skew-x-[-30deg] bg-indigo-100 opacity-20 ring-1 ring-inset ring-white"
                  aria-hidden="true"
                />
                <div className="mx-auto max-w-2xl sm:mx-0 sm:max-w-none">
                  <img
                    src="https://images.unsplash.com/photo-1562646329-0d0f4d3bf503?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80"
                    alt="Product screenshot"
                    width={2432}
                    height={1442}
                    className="-mb-12 w-[57rem] max-w-none rounded-tl-xl bg-gray-800 ring-1 ring-white/10"
                  />
                </div>
                <div
                  className="pointer-events-none absolute inset-0 ring-1 ring-inset ring-black/10 sm:rounded-3xl"
                  aria-hidden="true"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageSolutionsSocialMedia;
