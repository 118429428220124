// Local - Data
import { industries } from "./data";

const PageIndustries: React.FC = () => {
  return (
    <>
      <div className="bg-white py-12 px-6 sm:py-16 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <p className="text-lg font-semibold leading-8 tracking-tight text-indigo-600">
            Get the help you need
          </p>
          <h2 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
            Our Industries Of Focus
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            With a steadfast commitment to innovation and a passion for
            delivering exceptional online experiences, we stand as your reliable
            partner across a multitude of industries. From empowering real
            estate ventures with immersive property showcases to creating
            enticing online spaces for culinary delights, and even fueling the
            ambitions of groundbreaking startups.
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            With a proven track record of tailoring solutions that seamlessly
            blend creativity, functionality, and industry-specific insights, we
            are dedicated to crafting digital solutions that resonate with your
            audience and drive success in every sector we touch. Explore the
            possibilities of a dynamic online presence with us and unlock the
            potential of your brand across diverse landscapes.
          </p>
        </div>
      </div>
      <div className="mx-auto max-w-7xl md:px-6 lg:px-8 my-4">
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-4">
          {industries.map((industry) => (
            <div
              key={industry.name}
              className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400"
            >
              <div className="flex-shrink-0">
                <img
                  className="h-10 w-10 rounded-full"
                  src={industry.imageUrl}
                  alt=""
                />
              </div>
              <div className="min-w-0 flex-1">
                <span className="absolute inset-0" aria-hidden="true" />
                <p className="text-sm font-medium text-gray-900">
                  {industry.name}
                </p>
                <p className="truncate text-sm text-gray-500">
                  {industry.role}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default PageIndustries;
