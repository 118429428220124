export const industries = [
  {
    name: "Real Estate",
    role: "Web, SEO, Marketing, Print",
    imageUrl: "https://images.unsplash.com/photo-1592595896551-12b371d546d5?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80",
    url: "/",
  },
  {
    name: "Restaurants",
    role: "Web, SEO, Marketing, Print",
    imageUrl: "https://images.unsplash.com/photo-1561222471-e96a96669d36?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=735&q=80",
    url: "/",
  },
  {
    name: "Churches",
    role: "Web, SEO, Marketing, Print",
    imageUrl: "https://images.unsplash.com/photo-1499652848871-1527a310b13a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1074&q=80",
    url: "/",
  },
  {
    name: "Financial Services",
    role: "Web, SEO, Marketing, Print",
    imageUrl: "https://images.unsplash.com/photo-1512428559087-560fa5ceab42?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80",
    url: "/",
  },
  {
    name: "Financial Technology",
    role: "Web, SEO, Marketing, Apps",
    imageUrl: "https://images.unsplash.com/photo-1535136104956-115a2cd67fc4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1035&q=80",
    url: "/",
  },
  {
    name: "Supply Chain",
    role: "Web, SEO, Marketing, Apps",
    imageUrl: "https://images.unsplash.com/photo-1627309366653-2dedc084cdf1?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1966&q=80",
    url: "/",
  },
  {
    name: "Crypto Currency",
    role: "Web, SEO, Marketing, Apps",
    imageUrl: "https://images.unsplash.com/photo-1605792657660-596af9009e82?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1102&q=80",
    url: "/",
  },
  {
    name: "Non-Profit",
    role: "Web, SEO, Marketing, Print",
    imageUrl: "https://images.unsplash.com/photo-1613399421095-41f5c68e9f8c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1169&q=80",
    url: "/",
  },
  {
    name: "AI & Machine Learning",
    role: "Web, Apps",
    imageUrl: "https://images.unsplash.com/photo-1526378722484-bd91ca387e72?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1334&q=80",
    url: "/",
  },
  {
    name: "Medical",
    role: "Web, SEO, Marketing, Apps",
    imageUrl: "https://images.unsplash.com/photo-1576091160399-112ba8d25d1d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80",
    url: "/",
  },
  {
    name: "Marketing",
    role: "Web, SEO, Marketing, Print",
    imageUrl: "https://images.unsplash.com/photo-1560472354-b33ff0c44a43?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1251&q=80",
    url: "/",
  },
  {
    name: "Startups",
    role: "Web, SEO, Marketing, Print, Apps",
    imageUrl: "https://images.unsplash.com/photo-1456406644174-8ddd4cd52a06?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1168&q=80",
    url: "/",
  },
];
