export const services = [
  {
    name: "React",
    role: "Code",
    imageUrl: "https://via.placeholder.com/150",
    url: "/",
  },
  {
    name: "React Native",
    role: "TBD",
    imageUrl: "https://via.placeholder.com/150",
    url: "/",
  },
  {
    name: "Excel",
    role: "TBD",
    imageUrl: "https://via.placeholder.com/150",
    url: "/",
  },
  {
    name: "BootStrap",
    role: "TBD",
    imageUrl: "https://via.placeholder.com/150",
    url: "/",
  },
  {
    name: "Tailwind",
    role: "TBD",
    imageUrl: "https://via.placeholder.com/150",
    url: "/",
  },
  {
    name: "Google Analytics",
    role: "TBD",
    imageUrl: "https://via.placeholder.com/150",
    url: "/",
  },
  {
    name: "Python",
    role: "TBD",
    imageUrl: "https://via.placeholder.com/150",
    url: "/",
  },
  {
    name: "PHP",
    role: "TBD",
    imageUrl: "https://via.placeholder.com/150",
    url: "/",
  },
  {
    name: "GoLang",
    role: "TBD",
    imageUrl: "https://via.placeholder.com/150",
    url: "/",
  },
  {
    name: "C# / .NET",
    role: "TBD",
    imageUrl: "https://via.placeholder.com/150",
    url: "/",
  },
  {
    name: "HTML",
    role: "TBD",
    imageUrl: "https://via.placeholder.com/150",
    url: "/",
  },
  {
    name: "CSS",
    role: "TBD",
    imageUrl: "https://via.placeholder.com/150",
    url: "/",
  },
  {
    name: "jQuery",
    role: "TBD",
    imageUrl: "https://via.placeholder.com/150",
    url: "/",
  },
  {
    name: "JavaScript",
    role: "TBD",
    imageUrl: "https://via.placeholder.com/150",
    url: "/",
  },
  {
    name: "TypeScript",
    role: "TBD",
    imageUrl: "https://via.placeholder.com/150",
    url: "/",
  },
  {
    name: "Figma",
    role: "TBD",
    imageUrl: "https://via.placeholder.com/150",
    url: "/",
  },
  {
    name: "Canva",
    role: "TBD",
    imageUrl: "https://via.placeholder.com/150",
    url: "/",
  },
  {
    name: "Webflow",
    role: "TBD",
    imageUrl: "https://via.placeholder.com/150",
    url: "/",
  },
  {
    name: "WordPress",
    role: "TBD",
    imageUrl: "https://via.placeholder.com/150",
    url: "/",
  },
  {
    name: "GoDaddy",
    role: "TBD",
    imageUrl: "https://via.placeholder.com/150",
    url: "/",
  },
  {
    name: "Google",
    role: "TBD",
    imageUrl: "https://via.placeholder.com/150",
    url: "/",
  },
  {
    name: "SOC 2",
    role: "Compliance",
    imageUrl: "https://via.placeholder.com/150",
    url: "/",
  },
  {
    name: "WCAG",
    role: "Compliance",
    imageUrl: "https://via.placeholder.com/150",
    url: "/",
  },
  {
    name: "GDPR",
    role: "Compliance",
    imageUrl: "https://via.placeholder.com/150",
    url: "/",
  },
  {
    name: "AWS",
    role: "TBD",
    imageUrl: "https://via.placeholder.com/150",
    url: "/",
  },
  {
    name: "Azure",
    role: "TBD",
    imageUrl: "https://via.placeholder.com/150",
    url: "/",
  },
  {
    name: "Graphic Design",
    role: "TBD",
    imageUrl: "https://via.placeholder.com/150",
    url: "/",
  },
  {
    name: "Logo Design",
    role: "TBD",
    imageUrl: "https://via.placeholder.com/150",
    url: "/",
  },
  {
    name: "Marketing Strategy",
    role: "TBD",
    imageUrl: "https://via.placeholder.com/150",
    url: "/",
  },
  {
    name: "Branding",
    role: "TBD",
    imageUrl: "https://via.placeholder.com/150",
    url: "/",
  },
  {
    name: "Chrome Extension",
    role: "TBD",
    imageUrl: "https://via.placeholder.com/150",
    url: "/",
  },
  {
    name: "SEO",
    role: "TBD",
    imageUrl: "https://via.placeholder.com/150",
    url: "/",
  },
  {
    name: "Digital Advertising",
    role: "TBD",
    imageUrl: "https://via.placeholder.com/150",
    url: "/",
  },
  {
    name: "Android Mobile Apps",
    role: "TBD",
    imageUrl: "https://via.placeholder.com/150",
    url: "/",
  },
  {
    name: "iOS Mobile Apps",
    role: "TBD",
    imageUrl: "https://via.placeholder.com/150",
    url: "/",
  },
  {
    name: "AngularJS",
    role: "TBD",
    imageUrl: "https://via.placeholder.com/150",
    url: "/",
  },
  {
    name: "Vue",
    role: "TBD",
    imageUrl: "https://via.placeholder.com/150",
    url: "/",
  },
  {
    name: "NextJS",
    role: "TBD",
    imageUrl: "https://via.placeholder.com/150",
    url: "/",
  },
  {
    name: "Blockchain",
    role: "TBD",
    imageUrl: "https://via.placeholder.com/150",
    url: "/",
  },
  {
    name: "AI - Artifiacial Intelligence",
    role: "TBD",
    imageUrl: "https://via.placeholder.com/150",
    url: "/",
  },
  {
    name: "ML - Machine Learning",
    role: "TBD",
    imageUrl: "https://via.placeholder.com/150",
    url: "/",
  },
  {
    name: "E-Commerce",
    role: "TBD",
    imageUrl: "https://via.placeholder.com/150",
    url: "/",
  },
  {
    name: "Google Sheets",
    role: "TBD",
    imageUrl: "https://via.placeholder.com/150",
    url: "/",
  },
  {
    name: "Office 365",
    role: "TBD",
    imageUrl: "https://via.placeholder.com/150",
    url: "/",
  },
];
